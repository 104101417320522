
.bonus_wrapper{
    .bonus_container{

        img{
            height: 120% !important;
            max-height: 180px !important;
        }
    }
}

.side_bar_section > .btn img{
    filter:invert(1) !important;
}
.search_inner.open {
    height: 140px !important;}

.bonus_slider:before {
    background-size: cover;
}
.search_icon{
    @include recolor(#03be90)
}
.jackpot.value{
    color: #f1c37f;
}